import React from 'react';
import PropTypes from 'prop-types';
import { FaCalendarAlt } from 'react-icons/fa'

const EventCard = ({
  eventDay,
  eventMonth,
  eventTitle,
  allDay,
  dateRange,
  eventText,
  eventYear,
}) => (
  <div className="w-full h-80 bg-white shadow-md">
    <div className="relative h-full flex flex-col justify-between p-6">
      <div>
        <p className="text-6xl font-semibold text-red-900">
          {eventDay ? eventDay : '00'}
        </p>
        <p className="uppercase tracking-wider">
          {eventMonth ? eventMonth : 'Month'}
        </p>
      </div>

      <div>
        <h2 className="text-xl tex-gray-800 font-medium mb-2 line-clamp-2">
          {eventTitle ? eventTitle : 'Event Name'}
        </h2>
        <hr className="mb-3" />
        {dateRange ? (
          <div className="flex items-center mb-2 text-gray-800 text-base">
            <FaCalendarAlt className="text-gray-500 mr-2" /> {dateRange}{' '}
            <span className="text-xs text-white bg-gray-800 py-1 px-3 ml-2 rounded-xl">
              {allDay ? 'All Day' : null}
            </span>
          </div>
        ) : null}
        {eventText ? (
          <p className="text-sm text-gray-700 italic line-clamp-2">{eventText}</p>
        ) : null}
      </div>

      <div className="absolute top-0 right-0 m-6">
        <h2 className="text-red-100 text-3xl font-black">{eventYear ? eventYear : null}</h2>
      </div>
    </div>
  </div>
);

EventCard.propTypes = {
  eventDay: PropTypes.string,
  eventMonth: PropTypes.string,
  eventTitle: PropTypes.string,
  allDay: PropTypes.bool,
  dateRange: PropTypes.string,
  eventText: PropTypes.string,
  eventYear: PropTypes.string,
};

export default EventCard;
