import React from 'react';
import { compose } from 'recompose';

import DashboardWrapper from '../components/Dashboard/DashboardWrapper';
import Layout from '../components/layout';
import KeyDatesSectionUpdate from '../components/Sections/KeyDatesSectionUpdate';
import {
  withAuthorization,
} from '../components/Session';
import HeadData from "../data/HeadData"

const KeyDatesPageBase = () => (
  <DashboardWrapper pageName='Key Dates'>
    <div className="relative pt-12 pb-32 md:pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        <KeyDatesSectionUpdate />
      </div>
    </div>
  </DashboardWrapper>
);

const condition = authUser => !!authUser;

const KeyDatesPage = compose(
  withAuthorization(condition),
)(KeyDatesPageBase);

const KeyDates = () => (
  <Layout>
    <HeadData title='Key Dates' />
    <KeyDatesPage />
  </Layout>
)

export default KeyDates
