import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

import EventCard from '../Common/EventCard';

const StyledDiv = styled.div`
  .react-tabs__tab-list {
    margin: 0px;
    border-bottom: 1px solid #b02929;
  }
  .react-tabs__tab {
    font-weight: bold;
    background: #403E42;
    color: #fff;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 8px;
    margin-right: 2px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #b02929;
  }
  .react-tabs__tab--selected,
  .react-tabs__tab:hover {
    background: #b02929;
    color: #fff;
  }
  .react-tabs__tab-panel {
    padding: 24px 0;
    background: transparent;
  }
`

const KeyDatesSectionUpdate = () => {
  const data = useStaticQuery(graphql`
    query KeyDatesSectionUpdateQuery {
      prismicKeyDates {
        data {
          title
          section_title
          section_description {
            richText
          }
          section_title1
          key_dates_list_2022 {
            event_day
            event_month
            event_title
            date_range
            event_description
            all_day
          }
          section_title2
          key_dates_list_2023 {
            event_day2
            event_month2
            event_title2
            date_range
            event_description2
            all_day2
          }
          section_title3
          key_dates_list_2024 {
            event_day3
            event_month3
            event_title3
            date_range
            event_description3
            all_day3
          }
        }
      }
    }
  `);

  const doc = data.prismicKeyDates

  return (
    <>
      <div className="w-full max-w-screen-lg mt-6 mb-16">
        <h2 className='text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold'>{doc.data.section_title}</h2>
        {doc.data.section_description.richText
          ?
          <div className="mt-6">
            <RichText
              render={doc.data.section_description.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
          :
          null
        }
      </div>

      <StyledDiv>
        <Tabs>
          <TabList>
            <Tab>{doc.data.section_title3}</Tab>
            <Tab>{doc.data.section_title2}</Tab>
            <Tab>{doc.data.section_title1}</Tab>
          </TabList>

          <TabPanel>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
            {doc.data.key_dates_list_2024.map(item => {
                return (
                  item.event_description3 && item.all_day3
                  ?
                  <EventCard
                    eventDay={item.event_day3}
                    eventMonth={item.event_month3}
                    eventTitle={item.event_title3}
                    dateRange={item.date_range}
                    eventText={item.event_description3}
                    allDay
                    eventYear="2024"
                  />
                  :
                  item.event_description3
                  ?
                  <EventCard
                    eventDay={item.event_day3}
                    eventMonth={item.event_month3}
                    eventTitle={item.event_title3}
                    dateRange={item.date_rang3}
                    eventText={item.event_description3}
                    eventYear="2024"
                  />
                  :
                  item.all_day3
                  ?
                  <EventCard
                    eventDay={item.event_day3}
                    eventMonth={item.event_month3}
                    eventTitle={item.event_title3}
                    dateRange={item.date_range}
                    allDay
                    eventYear="2024"
                  />
                  :
                  <EventCard
                    eventDay={item.event_day3}
                    eventMonth={item.event_month3}
                    eventTitle={item.event_title3}
                    dateRange={item.date_range}
                    eventYear="2024"
                  />
                )
              })}
            </div>
          </TabPanel>

          <TabPanel>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
            {doc.data.key_dates_list_2023.map(item => {
                return (
                  item.event_description2 && item.all_day2
                  ?
                  <EventCard
                    eventDay={item.event_day2}
                    eventMonth={item.event_month2}
                    eventTitle={item.event_title2}
                    dateRange={item.date_range}
                    eventText={item.event_description2}
                    allDay
                    eventYear="2023"
                  />
                  :
                  item.event_description2
                  ?
                  <EventCard
                    eventDay={item.event_day2}
                    eventMonth={item.event_month2}
                    eventTitle={item.event_title2}
                    dateRange={item.date_range}
                    eventText={item.event_description2}
                    eventYear="2023"
                  />
                  :
                  item.all_day2
                  ?
                  <EventCard
                    eventDay={item.event_day2}
                    eventMonth={item.event_month2}
                    eventTitle={item.event_title2}
                    dateRange={item.date_range}
                    allDay
                    eventYear="2023"
                  />
                  :
                  <EventCard
                    eventDay={item.event_day2}
                    eventMonth={item.event_month2}
                    eventTitle={item.event_title2}
                    dateRange={item.date_range}
                    eventYear="2023"
                  />
                )
              })}
            </div>
          </TabPanel>

          <TabPanel>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
              {doc.data.key_dates_list_2022.map(item => {
                return (
                  item.event_description && item.all_day
                  ?
                  <EventCard
                    eventDay={item.event_day}
                    eventMonth={item.event_month}
                    eventTitle={item.event_title}
                    dateRange={item.date_range}
                    eventText={item.event_description}
                    allDay
                    eventYear="2022"
                  />
                  :
                  item.event_description
                  ?
                  <EventCard
                    eventDay={item.event_day}
                    eventMonth={item.event_month}
                    eventTitle={item.event_title}
                    dateRange={item.date_range}
                    eventText={item.event_description}
                    eventYear="2022"
                  />
                  :
                  item.all_day
                  ?
                  <EventCard
                    eventDay={item.event_day}
                    eventMonth={item.event_month}
                    eventTitle={item.event_title}
                    dateRange={item.date_range}
                    allDay
                    eventYear="2022"
                  />
                  :
                  <EventCard
                    eventDay={item.event_day}
                    eventMonth={item.event_month}
                    eventTitle={item.event_title}
                    dateRange={item.date_range}
                    eventYear="2022"
                  />
                )
              })}
            </div>
          </TabPanel>
        </Tabs>
      </StyledDiv>
    </>
  )
};

export default KeyDatesSectionUpdate;
